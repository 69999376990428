import Logo from './images/logo-groundcontrol-in-hamburg.svg';

function Header() {
    return (
        <nav className="header">
            <div className="logo">
                <a href="https://ground-control.de" title="GroundControl Hamburg - TYPO3 Freelance Web Development und Beratung">
                    <img src={Logo} alt="Logo GroundControl Hamburg" />
                </a>
            </div>
        </nav>
    );
}

export default Header;
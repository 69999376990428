import AnglesDown from './images/angles-down.svg';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function Section1() {
    return (
        <div className="section">
            <Container>
                <Row>
                    <Col>
                        <h3 className="fadeInBlock start mb-4">
                            <span>Schonungslos ehrlich</span><br/>
                            <span>und pragmatisch.</span><br/>
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <p>Wir haben unsere eigene Sicht auf die Dinge und werden Ihnen keine Raketentechnik verkaufen. Denn in der Regel brauchen Sie die nicht. Und besser heißt nicht automatisch besser. Es sei denn, Sie möchten das Rad neu erfinden. Wir liefern schnell und werden Sie für die nächste Runde begeistern. </p>
                        <a href="#arbeitsweise" title="weiter zu unserer Arbeitsweise" className="scroll-btn">
                            <Image src={AnglesDown} alt="" />
                        </a>
                    </Col>
                    <Col md={6}></Col>
                </Row>
                <div className="image-bg bg-2"></div>
            </Container>
        </div>
    );
}

export default Section1;
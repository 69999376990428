import AnglesDown from './images/angles-down.svg';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function Section1() {
    return (
        <div className="section">
            <Container>
                <Row>
                    <Col>
                        <h3 className="fadeInBlock start mb-4">
                            <span>Zusammenarbeit</span><br/>
                            <span>auf Augenhöhe.</span><br/>
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <p>Wir pflegen hanseatische Tugenden und sind große Freunde von Verlässlichkeit, Anstand und Fairness. Das Schnacken überlassen wir den Anderen, wir hören Ihnen lieber zu. Verträge brauchen wir keine, uns reicht ein Handschlag - dann geht's auch schon los. <br/>Unser Credo: Einfach mal machen.</p>
                        <a href="#leistungen" title="weiter zu unseren Leistungen" className="scroll-btn">
                            <Image src={AnglesDown} alt="" />
                        </a>
                    </Col>
                    <Col md={6}></Col>
                </Row>
                <div className="image-bg bg-3"></div>
            </Container>
        </div>
    );
}

export default Section1;
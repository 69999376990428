import AnglesDown from './images/angles-down.svg';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function Section1() {
    return (
        <div className="section">
            <Container>
                <Row>
                    <Col>
                        <h3 className="fadeInBlock start mb-4">
                            <span>All you need</span><br/>
                            <span>is passion and speed.</span><br/>
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={7}>
                        <p>Wir sind leidenschaftliche Web Worker und unterstützen Sie bei der Realisierung Ihrer Online-Projekte.
                            Mit 25 Jahren Erfahrung, agilen Arbeitsmethoden und einem großen Netzwerk garantieren wir, dass Sie Ihr Ziel erreichen.
                            In feinster Qualität, mit klar überschaubaren Kosten und in persönlicher Bestzeit - natürlich nur, wenn Sie wollen.</p>
                        <a href="#philosophie" title="weiter zu unserer Philosophie" className="scroll-btn">
                            <Image src={AnglesDown} alt="" />
                        </a>
                    </Col>
                    <Col md={6}></Col>
                </Row>
                <div className="image-bg bg-1"></div>
            </Container>
        </div>
    );
}

export default Section1;
import AnglesDown from './images/angles-down.svg';
import PaperPlane from './images/paperplane-groundcontrol-in-hamburg.svg';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

function Section4() {
    return (
        <div className="section">
            <div className="slide">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>Das machen wir</span><br/>
                                <span>am liebsten.</span><br/>
                            </h3>
                            <a href="#werkzeuge" title="weiter zu unseren Werkzeugen" className="scroll-btn">
                                <Image src={AnglesDown} alt="" />
                            </a>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Col xs={6} md={3} className="item mb-3">
                            <a href="#leistungen/web-konzeption-und-beratung" title="Informationen zu Web-Konzeption und Beratung">
                                <div className="card p-3">
                                    <div className="detached">Web-Konzeption<br/>und Beratung</div>
                                </div>
                            </a>
                        </Col>
                        <Col xs={6} md={3} className="item mb-3">
                            <a href="#leistungen/projektmanagement" title="Informationen zu Projektmanagement">
                                <div className="card p-3">
                                    <div className="detached">Projekt-<br/>Management</div>
                                </div>
                            </a>
                        </Col>
                        <Col xs={6} md={3} className="item mb-3">
                            <a href="#leistungen/online-marketing_und-seo" title="Informationen zu Online-Marketing, SEO und SEA">
                                <div className="card p-3">
                                    <div className="detached">Online-Marketing<br/>SEO und SEA</div>
                                </div>
                            </a>
                        </Col>
                        <Col xs={6} md={3} className="item mb-3">
                            <a href="#leistungen/schulungen-und-coachings" title="Informationen zu Schulungen und Coachings">
                                <div className="card p-3">
                                    <div className="detached">Schulungen und Coachings</div>
                                </div>
                            </a>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Col xs={6} md={3} className="item mb-3">
                            <a href="#leistungen/frontend-development" title="Informationen zu Frontend Development">
                                <div className="card p-3">
                                    <div className="detached">Frontend<br/>Development</div>
                                </div>
                            </a>
                        </Col>
                        <Col xs={6} md={3} className="item mb-3">
                            <a href="#leistungen/typo3-integration-und-development" title="Informationen zu TYPO3 Integration und Development">
                                <div className="card p-3">
                                    <div className="detached">TYPO3 Integration und Development</div>
                                </div>
                            </a>
                        </Col>
                        <Col xs={6} md={3} className="item mb-3">
                            <a href="#leistungen/shopware-development" title="Informationen zu Shopware Development">
                                <div className="card p-3">
                                    <div className="detached">Shopware Development</div>
                                </div>
                            </a>
                        </Col>
                        <Col xs={6} md={3} className="item mb-3">
                            <a href="#leistungen/hosting-und-support" title="Informationen zu Hosting und Support">
                                <div className="card p-3">
                                    <div className="detached">Hosting und<br/>Support</div>
                                </div>
                            </a>
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-4"></div>
            </div>
            <div className="slide" data-anchor="web-konzeption-und-beratung">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>Web-Konzeption</span><br/>
                                <span>und Beratung</span><br/>
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <p>Wir sind gerne Ihr Ansprechpartner bei den Themen:</p>
                            <ul className="list-checked">
                                <li>Website-Relaunch und langfristige Betreuung</li>
                                <li>IST-Analyse</li>
                                <li>Quick-wins und langfristige Ziele</li>
                                <li>Strategische Beratung</li>
                                <li>Automatisierung und Nutzung von KI</li>
                                <li>Blick über den Tellerrand</li>
                            </ul>

                            <ButtonGroup aria-label="Legal Information" className="mt-4 gx-3">
                                <Button
                                    href="#leistungen"
                                    title="Zurück zur Übersicht"
                                    variant="outline-light"
                                >
                                    zurück
                                </Button>
                                <Button
                                    href="#leistungen/projektmanagement"
                                    title="Weiter zum Schwerpunkt Projektmanagement"
                                    variant="outline-light"
                                >
                                    weiter
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center">
                            <Image src={PaperPlane} className="w-50" alt="paperplane" />
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-red"></div>
            </div>
            <div className="slide" data-anchor="projektmanagement">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>Projekt-</span><br/>
                                <span>Management</span><br/>
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <p>Wir sind gerne Ihr Ansprechpartner bei den Themen:</p>
                            <ul className="list-checked">
                                <li>Projektleitung und Controlling</li>
                                <li>Technische Konzeption</li>
                                <li>Requirements engineering</li>
                                <li>Qualitätssicherung und Testing</li>
                                <li>Dokumentation</li>
                            </ul>

                            <ButtonGroup aria-label="Legal Information" className="mt-4 gx-3">
                                <Button
                                    href="#leistungen/web-konzeption-und-beratung"
                                    title="Zurück zum Schwerpunkt Web-Konzeption und Beratung"
                                    variant="outline-light"
                                >
                                    zurück
                                </Button>
                                <Button
                                    href="#leistungen/online-marketing_und-seo"
                                    title="Weiter zum Schwerpunkt Online-Marketing SEO und SEA"
                                    variant="outline-light"
                                >
                                    weiter
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center">
                            <Image src={PaperPlane} className="w-50" alt="paperplane" />
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-red"></div>
            </div>
            <div className="slide" data-anchor="online-marketing_und-seo">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>Online-Marketing</span><br/>
                                <span>SEO und SEA</span><br/>
                            </h3>
                            <ul className="list-checked">
                                <li>Conversion-Optimierung</li>
                                <li>Kampagnen und Microsites</li>
                                <li>Google Merchant Center</li>
                                <li>Storytelling</li>
                                <li>Newsletter-Marketing</li>
                                <li>Pagespeed-Optimierung</li>
                            </ul>

                            <ButtonGroup aria-label="Legal Information" className="mt-4 gx-3">
                                <Button
                                    href="#leistungen/projektmanagement"
                                    title="Zurück zum Schwerpunkt Projektmanagement"
                                    variant="outline-light"
                                >
                                    zurück
                                </Button>
                                <Button
                                    href="#leistungen/schulungen-und-coachings"
                                    title="Weiter zum Schwerpunkt Schulungen und Coachings"
                                    variant="outline-light"
                                >
                                    weiter
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center">
                            <Image src={PaperPlane} className="w-50" alt="paperplane" />
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-red"></div>
            </div>
            <div className="slide" data-anchor="schulungen-und-coachings">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>Schulungen und</span><br/>
                                <span>Coachings</span><br/>
                            </h3>
                            <ul className="list-checked">
                                <li>TYPO3-Schulungen für Redakteure und Administratoren</li>
                                <li>Shopware-Schulungen für Redakteure und Administratoren</li>
                                <li>Workshop-Moderation</li>
                                <li>Retrospektiven</li>
                                <li>Kanban Training</li>
                                <li>Lean Thinking</li>
                            </ul>

                            <ButtonGroup aria-label="Legal Information" className="mt-4 gx-3">
                                <Button
                                    href="#leistungen/online-marketing_und-seo"
                                    title="Zurück zum Schwerpunkt Online-Marketing, SEO und SEA"
                                    variant="outline-light"
                                >
                                    zurück
                                </Button>
                                <Button
                                    href="#leistungen/frontend-development"
                                    title="Weiter zum Schwerpunkt Frontend Development"
                                    variant="outline-light"
                                >
                                    weiter
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center">
                            <Image src={PaperPlane} className="w-50" alt="paperplane" />
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-red"></div>
            </div>
            <div className="slide" data-anchor="frontend-development">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>Frontend</span><br/>
                                <span>Development</span><br/>
                            </h3>
                            <ul className="list-checked">
                                <li>HTML- und CSS-Templating</li>
                                <li>Web Content Accessibility Guidelines (WCAG)</li>
                                <li>Javascript / TypeScript</li>
                                <li>React</li>
                                <li>Rapid Prototyping</li>
                                <li>Visualisierung von Daten</li>
                            </ul>

                            <ButtonGroup aria-label="Legal Information" className="mt-4 gx-3">
                                <Button
                                    href="#leistungen/schulungen-und-coachings"
                                    title="Zurück zum Schwerpunkt Frontend Development"
                                    variant="outline-light"
                                >
                                    zurück
                                </Button>
                                <Button
                                    href="#leistungen/typo3-integration-und-development"
                                    title="Weiter zum Schwerpunkt TYPO3 Integration und Development"
                                    variant="outline-light"
                                >
                                    weiter
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center">
                            <Image src={PaperPlane} className="w-50" alt="paperplane" />
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-red"></div>
            </div>
            <div className="slide" data-anchor="typo3-integration-und-development">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>TYPO3 Integration</span><br/>
                                <span>und Development</span><br/>
                            </h3>
                            <ul className="list-checked">
                                <li>Typoscript-Programmierung</li>
                                <li>Template-Integration und Erweiterung</li>
                                <li>Fluid-Templating</li>
                                <li>Extension-Entwicklung</li>
                                <li>Extbase-Programmierung</li>
                                <li>TYPO3-Updates / ELTS</li>
                            </ul>

                            <ButtonGroup aria-label="Legal Information" className="mt-4 gx-3">
                                <Button
                                    href="#leistungen/frontend-development"
                                    title="Zurück zum Schwerpunkt Frontend Development"
                                    variant="outline-light"
                                >
                                    zurück
                                </Button>
                                <Button
                                    href="#leistungen/shopware-development"
                                    title="Weiter zum Schwerpunkt Shopware Development"
                                    variant="outline-light"
                                >
                                    weiter
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center">
                            <Image src={PaperPlane} className="w-50" alt="paperplane" />
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-red"></div>
            </div>
            <div className="slide" data-anchor="shopware-development">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>Shopware</span><br/>
                                <span>Development</span><br/>
                            </h3>
                            <ul className="list-checked">
                                <li>Shop-Installation</li>
                                <li>Migration und Updates</li>
                                <li>Templating</li>
                                <li>Plugin-Entwicklung 5/6</li>
                                <li>ERP-Anbindung</li>
                            </ul>

                            <ButtonGroup aria-label="Legal Information" className="mt-4 gx-3">
                                <Button
                                    href="#leistungen/typo3-integration-und-development"
                                    title="Zurück zum Schwerpunkt TYPO3 Integration und Development"
                                    variant="outline-light"
                                >
                                    zurück
                                </Button>
                                <Button
                                    href="#leistungen/hosting-und-support"
                                    title="Weiter zum Schwerpunkt Hosting und Support"
                                    variant="outline-light"
                                >
                                    weiter
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center">
                            <Image src={PaperPlane} className="w-50" alt="paperplane" />
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-red"></div>
            </div>
            <div className="slide" data-anchor="hosting-und-support">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>Hosting und</span><br/>
                                <span>Support</span><br/>
                            </h3>
                            <ul className="list-checked">
                                <li>Migration von Web-Projekten</li>
                                <li>Caching und Performance</li>
                                <li>Hosting-Anbieter-Auswahl</li>
                                <li>SSL-Zertifikate und Support</li>
                                <li>Security Audits</li>
                            </ul>

                            <ButtonGroup aria-label="Legal Information" className="mt-4 gx-3">
                                <Button
                                    href="#leistungen/shopware-development"
                                    title="Zurück zum Schwerpunkt Shopware Development"
                                    variant="outline-light"
                                >
                                    zurück
                                </Button>
                                <Button
                                    href="#leistungen"
                                    title="Weiter zur Übersicht"
                                    variant="outline-light"
                                >
                                    weiter
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center">
                            <Image src={PaperPlane} className="w-50" alt="paperplane" />
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-red"></div>
            </div>
        </div>
    );
}

export default Section4;
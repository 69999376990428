import LogoGuppyDesign from './images/logo_guppy-design.svg';
import LogoPegasus from './images/logo_pegasus.svg';
import LogoBlightDesign from './images/logo_bld.svg';
import LogoEhrenberg360 from './images/logo_ehrenberg360.svg';
import LogoAtlantisDx from './images/logo_atlantis-dx.svg';
import LogoSitegeist from './images/logo_sitegeist.svg';
import LogoPlanA from './images/logo_plana.svg';
import LogoSpurwerk from './images/logo_spurwerk.svg';
import LogoDiekmann from './images/logo_diekmann-rechtsanwaelte.svg';
import LogoReiseland from './images/logo_reiseland.svg';
import LogoJobKontor from './images/logo_jobkontor.svg';
import LogoNieWiederBohren from './images/logo_nie-wieder-bohren.svg';
import LogoFachhochschuleKiel from './images/logo_fachhochschule-kiel.svg';
import LogoEbikeMadeira from './images/logo_e-bike-madeira.svg';
import LogoSac from './images/logo_sac.svg';
import LogoMadeiraAz from './images/logo_madeira-a-z.svg';
import LogoPantaenius from './images/logo_pantaenius.svg';
import LogoTroxCar from './images/logo_troxcar.svg';
import LogoPharmsec from './images/logo_pharmsec.svg';
import LogoZimScholz from './images/logo_zim-scholz.svg';
import LogoFredRompelberg from './images/logo_fred-rompelberg.svg';
import LogoSteidlBecker from './images/logo_steidl-becker.svg';
import LogoUptimeIt from './images/logo_uptime-it.svg';
import LogoCrsMedical from './images/logo_crs-medical.svg';

import AnglesDown from "./images/angles-down.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function Section6() {
    return (
        <div className="section">
            <div className="slide s-6">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>Digitale Gelassenheit</span>
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <p>Mit den hier aufgeführten Kunden und Agenturen arbeiten wir zum Teil seit sehr langer Zeit zusammen. Die realisierten Projekte stehen unter NDA – daher dürfen Details nicht publiziert werden. Sprechen Sie uns bei Fragen gerne an.</p>
                            <a href="#kontakt" title="weiter zu den Kontaktmöglichkeiten" className="scroll-btn">
                                <Image src={AnglesDown} alt="" />
                            </a>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoGuppyDesign} alt="GUPPY DESIGN GmbH " />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoEhrenberg360} alt="Ehrenberg 360° GmbH" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoSitegeist} alt="sitegeist media solutions GmbH" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoAtlantisDx} alt="atlantis dx GmbH" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoSpurwerk} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoBlightDesign} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoPlanA} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoPegasus} alt="PEGASUS Werbeagentur GmbH" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-6"></div>
            </div>
            <div className="slide" data-anchor="referenzen-2">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>Digitale Gelassenheit</span>
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <p>Mit den hier aufgeführten Kunden und Agenturen arbeiten wir zum Teil seit sehr langer Zeit zusammen. Die realisierten Projekte stehen unter NDA – daher dürfen Details nicht publiziert werden. Sprechen Sie uns bei Fragen gerne an.</p>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoNieWiederBohren} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoDiekmann} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoCrsMedical} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoReiseland} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoSac} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoEbikeMadeira} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoMadeiraAz} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoFachhochschuleKiel} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-6"></div>
            </div>
            <div className="slide" data-anchor="referenzen-3">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="fadeInBlock start mb-4">
                                <span>Digitale Gelassenheit</span>
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <p>Mit den hier aufgeführten Kunden und Agenturen arbeiten wir zum Teil seit sehr langer Zeit zusammen. Die realisierten Projekte stehen unter NDA – daher dürfen Details nicht publiziert werden. Sprechen Sie uns bei Fragen gerne an.</p>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoSteidlBecker} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoFredRompelberg} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoPantaenius} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoTroxCar} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoPharmsec} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoZimScholz} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoJobKontor} alt="" />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="item mb-3">
                            <div className="card logo p-3">
                                <img src={LogoUptimeIt} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="image-bg-slide bg-6"></div>
            </div>
        </div>
    );
}

export default Section6;
import LogoShopware from './images/logo-shopware.svg';
import LogoTypo3 from './images/logo-typo3.svg';
import LogoDocker from './images/logo-docker.svg';
import LogoWordpress from './images/logo-wordpress.svg';
import LogoReact from './images/logo-reactjs.svg';
import LogoBootstrap from './images/logo-bootstrap.svg';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AnglesDown from "./images/angles-down.svg";
import Image from "react-bootstrap/Image";

function Section5() {
    return (
        <div className="section">
            <Container>
                <Row>
                    <Col>
                        <h3 className="fadeInBlock start mb-4">
                            <span>Software</span><br/>
                            <span>und Werkzeuge.</span><br/>
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>Wir sind große Freunde von Open-Source-Produkten und Standard-Software. Neben Branchengrößen wie TYPO3, Shopware oder React
                            arbeiten wir auch gerne mit jungen Hunden wie Docker, DDEV, Git, npm und Tailwind. Der Blick übern Tellerrand ist für uns selbstverständlich.</p>
                        <a href="#referenzen" title="weiter zu unseren Referenzen" className="scroll-btn">
                            <Image src={AnglesDown} alt="" />
                        </a>
                    </Col>
                    <Col md={6}></Col>
                </Row>
                <Row>
                    <Col xs={6} md={2} className="d-flex justify-content-center mb-4">
                        <img src={LogoTypo3} alt="TYPO3" className="img-fluid" />
                    </Col>
                    <Col xs={6} md={2} className="d-flex justify-content-center mb-4">
                        <img src={LogoShopware} alt="Shopware" className="img-fluid" />
                    </Col>
                    <Col xs={6} md={2} className="d-flex justify-content-center mb-4">
                        <img src={LogoReact} alt="ReactJs" className="img-fluid" />
                    </Col>
                    <Col xs={6} md={2} className="d-flex justify-content-center mb-4">
                        <img src={LogoWordpress} alt="Docker" className="img-fluid" />
                    </Col>
                    <Col xs={6} md={2} className="d-flex justify-content-center mb-4">
                        <img src={LogoBootstrap} alt="Bootstrap" className="img-fluid" />
                    </Col>
                    <Col xs={6} md={2} className="d-flex justify-content-center mb-4">
                        <img src={LogoDocker} alt="Docker" className="img-fluid" />
                    </Col>
                </Row>
                <div className="image-bg bg-5"></div>
            </Container>
        </div>
    );
}

export default Section5;
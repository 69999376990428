import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactFullpage from '@fullpage/react-fullpage';
import Header from './Header';
import OffcanvasMenu from './OffcanvasMenu';
import Section1 from "./Section1"; // Home
import Section2 from "./Section2"; // Philosophie
import Section3 from "./Section3"; // Arbeitsweise
import Section4 from "./Section4"; // Leistungen
import Section5 from "./Section5"; // Werkzeuge
import Section6 from "./Section6"; // Referenzen
import Section7 from "./Section7"; // Unterstützung
import Section8 from "./Section8"; // Kontakt
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/Main.scss';
//const SEL = "custom-section";
//const SECTION_SEL = `.${SEL}`;

// NOTE: if using fullpage extensions/plugins put them here and pass it as props.
const pluginWrapper = () => {
    /*
     * require('./fullpage.fadingEffect.min'); // Optional. Required when using the "fadingEffect" extension.
     */
};

class App extends React.Component {
    /*
    constructor(props) {
        super(props);
    }
    */

    onLeave(origin, destination, direction) {
        console.log("onLeave", { origin, destination, direction });
        // arguments are mapped in order of fullpage.js callback arguments do something
        // with the event
    }

    render() {

        return (
            <div className="App">
                <Header />
                <OffcanvasMenu />
                <ReactFullpage
                    //debug /* Debug logging */
                    // Required when using extensions
                    pluginWrapper={pluginWrapper}
                    // fullpage options
                    licenseKey={"NQ9N6-3L2IK-2NKSJ-2JM3I-OQJRN"} // Get one from https://alvarotrigo.com/fullPage/pricing/
                    credits={{
                        enabled: false, // Credits deaktivieren
                    }}
                    navigation
                    controlArrows={false}
                    slidesNavigation
                    navigationTooltips={['home', 'philosophie', 'arbeitsweise', 'leistungen', 'werkzeuge', 'referenzen', 'unterstuetzung', 'kontakt']}
                    anchors={['home', 'philosophie', 'arbeitsweise', 'leistungen', 'werkzeuge', 'referenzen', 'unterstuetzung', 'kontakt']}
                    //sectionSelector={SECTION_SEL}
                    //onLeave={this.onLeave.bind(this)}
                    //sectionsColor={this.state.sectionsColor}
                    render={(comp) => (
                        <ReactFullpage.Wrapper>
                            <Section1 />
                            <Section2 />
                            <Section3 />
                            <Section4 />
                            <Section5 />
                            <Section6 />
                            <Section7 />
                            <Section8 />
                        </ReactFullpage.Wrapper>
                    )}
                />
            </div>
        );
    }
}

const root = ReactDOM.createRoot(document.getElementById('gc-root'));
root.render(<App />);
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

function Section8() {
    return (
        <div className="section">
            <Container>
                <Row>
                    <Col className="fadeInBlock start mb-4">
                        <h3 className="fadeInBlock start">
                            <span>Auf eine Tasse mit...</span><br/>
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Wir freuen uns über Ihre Anfrage.<br/>Sie erreichen uns am besten telefonisch oder per E-Mail.</p>
                        <p>
                            <a className="btn btn-outline-light mb-3" href="tel:04043092520" target="_blank" rel="noreferrer" title="Anrufen">+49 (0)40 / 43092520</a><br/>
                            <a className="btn btn-outline-light" href="mailto:hello@ground-control.de?SUBJECT=Anfrage" target="_blank" rel="noreferrer" title="E-Mail schreiben">hello@ground-control.de</a>
                        </p>
                    </Col>
                    <Col md={6}></Col>
                </Row>
                <div className="image-bg bg-8"></div>
            </Container>
        </div>
    );
}

export default Section8;
import LogoKinderschutzbund from './images/logo_kinderschutzbund.svg';
import LogoRsf from './images/logo_rsf.svg';
import LogoByteFm from './images/logo_bytefm.svg';
import LogoUnited4Rescue from './images/logo_united4rescue.svg';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AnglesDown from "./images/angles-down.svg";
import Image from "react-bootstrap/Image";

function Section7() {
    return (
        <div className="section">
            <Container>
                <Row>
                    <Col>
                        <h3 className="fadeInBlock start mb-4">
                            <span>Die hier finden wir</span><br/>
                            <span>richtig gut.</span><br/>
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>Unsere Mitgliedschaften und Förderprojekte</p>
                        <a href="#kontakt" title="weiter zu den Kontaktmöglichkeiten" className="scroll-btn">
                            <Image src={AnglesDown} alt="" />
                        </a>
                    </Col>
                    <Col md={6}></Col>
                </Row>
                <Row className="gx-3">
                    <Col xs={6} md={3} className="mb-3">
                        <div className="box">
                            <img src={LogoKinderschutzbund} alt="" className="img-fluid" />
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="mb-3">
                        <div className="box">
                            <img src={LogoRsf} alt="" className="img-fluid" />
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="mb-3">
                        <div className="box">
                            <img src={LogoUnited4Rescue} alt="" className="img-fluid" />
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="mb-3">
                        <div className="box">
                            <img src={LogoByteFm} alt="" className="img-fluid" />
                        </div>
                    </Col>
                </Row>
                <div className="image-bg bg-7"></div>
            </Container>
        </div>
    );
}

export default Section7;
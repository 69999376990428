import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
//import { useRef } from 'react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

function OffcanvasMenu() {

    //const [show, setShow] = useState(false);

    //const handleClose = () => setShow(false);

    const [smShow, setSmShow] = useState(false);
    const [lgShow, setLgShow] = useState(false);

    // Zustand für die "checked"-Eigenschaft der Checkbox
    const [isChecked, setIsChecked] = useState(false);

    // Handler für das Schließen des Menüs
    const handleMenuClick = () => {
        setIsChecked(false);
        //console.log(isChecked)
    };

    return (
        <div>
            <input
                type="checkbox"
                id="off-canvas-menu"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
            />
            <label htmlFor="off-canvas-menu" className="off-canvas-menu-label">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/></svg>
            </label>
            <nav className="off-canvas-menu" id="menu">
                <ul>
                    <li><a onClick={handleMenuClick} href="#home" title="Nach Hause">Home</a></li>
                    <li><a onClick={handleMenuClick} href="#philosophie" title="Philosophie">Philosophie</a></li>
                    <li><a onClick={handleMenuClick} href="#arbeitsweise" title="Arbeitsweise">Arbeitsweise</a></li>
                    <li><a onClick={handleMenuClick} href="#leistungen" title="Leistungen">Leistungen</a></li>
                    <li><a onClick={handleMenuClick} href="#werkzeuge" title="Werkzeuge">Werkzeuge</a></li>
                    <li><a onClick={handleMenuClick} href="#referenzen" title="Referenzen">Referenzen</a></li>
                    <li><a onClick={handleMenuClick} href="#kontakt" title="Kontakt">Kontakt</a></li>
                </ul>
                <div className="abstract">
                    <h1>TYPO3 und Shopware<br/> Freelance Web Development Sven Huppach</h1>
                    <a href="mailto:hello@ground-control.de?SUBJECT=Anfrage%20ground-control.de" title="E-Mail schreiben">hello@ground-control.de</a><br/>
                    <a href="tel:04043092520" title="Anrufen">04043092520</a><br/>
                    <ButtonGroup aria-label="Legal Information" className="mt-4">
                        <Button onClick={() => setSmShow(true)} variant="outline-light">
                            Impressum
                        </Button>
                        <Button onClick={() => setLgShow(true)} variant="outline-light">
                            Datenschutz
                        </Button>
                    </ButtonGroup>
                        <Modal
                            size="lg"
                            show={smShow}
                            onHide={() => setSmShow(false)}
                            backdrop="static"
                            keyboard={true}
                            data-bs-theme="dark"
                        >
                        <Modal.Header closeButton>
                            <Modal.Title>Impressum</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                <strong>Angaben gemäß § 5 TMG:</strong><br/>
                                Sven Huppach<br/>GroundControl<br/>Edgar-Roß-Straße 1 · 20251 Hamburg<br/>
                            </p>
                            <p>
                                <strong>Kontakt:</strong><br/>
                                Telefon: +49 (0) 40 / 23 51 76 82<br/>
                                E-Mail: <a href="mailto:hello@ground-control.de">hello@ground-control.de</a><br/>
                            </p>
                            <p>
                                <strong>Umsatzsteuer-ID:</strong><br/>
                                Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE221306811
                            </p>
                            <p><strong>Datenschutz</strong></p>
                            <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
                            <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.</p>
                            <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>

                            <p><strong>Cookies</strong></p>
                            <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.</p>
                            <p>Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p>
                            <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</p>
                            <p><strong>Server-Log-Files</strong></p>
                            <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
                            <ul><li>Browsertyp/ Browserversion verwendetes Betriebssystem</li>
                                <li>Referrer URL</li>
                                <li>Hostname des zugreifenden Rechners Uhrzeit der Serveranfrage</li></ul>
                            <p><strong>Kontaktformular</strong></p>
                            <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        size="lg"
                        show={lgShow}
                        onHide={() => setLgShow(false)}
                        backdrop="static"
                        keyboard={true}
                        data-bs-theme="dark"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                Datenschutzerklärung
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>1. DATENSCHUTZ AUF EINEN BLICK</h4>
                            <h5>Allgemeine Hinweise</h5>
                            <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
                            <h5>Datenerfassung auf unserer Website</h5>
                            <p><strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong></p>
                            <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.</p>
                            <p><strong>Wie erfassen wir Ihre Daten?</strong></p>
                            <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
                            <p>Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.</p>
                            <p><strong>Wofür nutzen wir Ihre Daten?</strong></p>
                            <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
                            <p><strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong></p>
                            <p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
                            <h5>Analyse-Tools und Tools von Drittanbietern</h5>
                            <p>Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerklärung.</p>
                            <p>Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung informieren.</p>

                            <h4>2. ALLGEMEINE HINWEISE UND PFLICHTINFORMATIONEN</h4>
                            <h5>Datenschutz</h5>
                            <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
                            <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.</p>
                            <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
                            <h5>Hinweise zur verantwortlichen Stelle</h5>
                            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                            <p>GroundControl<br/>
                                Sven Huppach<br/>
                                Edgar-Roß-Str. 1<br/>
                                20251 Hamburg
                            </p>
                            <p>	Telefon: 040 43092520<br/>
                                E-Mail: hello@ground-control.de
                            </p>
                            <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.</p>

                            <h5>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h5>
                            <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>

                            <h5>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h5>
                            <p>Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.</p>

                            <h5>Recht auf auf Datenübertragbarkeit</h5>
                            <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

                            <h5>SSL- bzw. TLS-Verschlüsselung</h5>
                            <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
                            <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>

                            <h5>Auskunft, Sperrung, Löschung</h5>
                            <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>

                            <h5>Widerspruch gegen Werbe-Mails</h5>
                            <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>

                            <h4>3. DATENERFASSUNG AUF UNSERER WEBSITE</h4>
                            <h5>Cookies</h5>
                            <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.</p>
                            <p>Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p>
                            <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</p>
                            <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.</p>

                            <h4>4. PLUGINS UND TOOLS</h4>
                            <h5>GoogleWeb Fonts</h5>
                            <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p>
                            <p>Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</p>
                            <p>Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.</p>
                            <p>Weitere Informationen zu Google Web Fonts finden Sie unter https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google: https://www.google.com/policies/privacy/.</p>
                        </Modal.Body>
                    </Modal>
                </div>
            </nav>
        </div>
    )
}

export default OffcanvasMenu;